import { QuickReplierProgress, ReplyTimeThreshold } from './constants'

export const getQuickRepliesProgress = (progress: number) => {
  if (progress === ReplyTimeThreshold.VerySlow) {
    return QuickReplierProgress.VerySlow
  }

  if (progress <= ReplyTimeThreshold.VeryFast) {
    return QuickReplierProgress.VeryFast
  }

  if (progress < ReplyTimeThreshold.Fast) {
    return QuickReplierProgress.Fast
  }

  if (progress < ReplyTimeThreshold.Moderate) {
    return QuickReplierProgress.Moderate
  }

  if (progress < ReplyTimeThreshold.Slow) {
    return QuickReplierProgress.Slow
  }

  return QuickReplierProgress.VerySlow
}
